
























































































import JnJuranetSearch from '@/views/search/JuranetSearch.vue';
import JnSearchResults from '@/views/search/SearchResults.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SearchData } from '@/models/search.model';
import { Testata } from '@/models/testata.model';
import { Sections } from '@/models/enums.model';
import { JnService } from '@/services/jn.service';
import { Pagination } from '@/models/pagination.model';
import { Document } from '@/models/document.model';
import MetaInfo from 'vue-meta';
import { Categories } from '@/models/enums.model';
import { EsService } from '@/services/es.service';
import JnDoc from '@/views/common/DocCard.vue';

@Component({
  components: {
    JnJuranetSearch,
    JnSearchResults,
    JnDoc
  },
  metaInfo(this: JnBancaDati): MetaInfo {
    return { title: 'JuraNews - Banca Dati' };
  },
})
export default class JnBancaDati extends Vue {
  testata?: Testata;

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANET_INDEXES!,
    perPage: 20,
    from: 0,
    size: 1000,
    searchScope: 'banca-dati',
  });

  sintesiPagination?: Pagination;
  sintesiAllDocs: Document[] = [];
  sintesiShownDocs: Document[] = [];

  sintesiSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Categories.SINTESI_STORICO],
    withText: true,
  });

  async created() {
    this.testata = JnService.getTestata(Sections.BANCA_DATI);

    this.sintesiAllDocs = await EsService.getDocs(this.sintesiSearchData);
    this.sintesiPagination = new Pagination({
      page: 1,
      total: this.sintesiAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showSintesi(0, this.sintesiPagination.perPage);
  }

  // Sintesi
  nextSintesi() {
    if (this.sintesiPagination) {
      const from =
        (this.sintesiPagination.page - 1) * this.sintesiPagination.perPage;
      const size = this.sintesiPagination.perPage;
      this.showSintesi(from, size);
    }
  }
  showSintesi(from: number, size: number) {
    this.sintesiShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.sintesiAllDocs[i]) {
        this.sintesiShownDocs.push(this.sintesiAllDocs[i]);
      }
    }
  }
}
